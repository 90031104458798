'use client'

import { PropsWithChildren, useState } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'

import { StoreCodeType, StoreConfigType } from '@/common/types'
import { queryClient as client } from '@/services'
import { AuthContextProvider } from './auth'
import { CartContextProvider } from './cart'
import { StoreContextProvider } from './store'

interface ProvidersNotFoundProps {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
  cartId?: string
}

export const ProvidersNotFound = ({
  children,
  storeCode,
  storeConfig,
  cartId,
}: PropsWithChildren<ProvidersNotFoundProps>) => {
  const [queryClient] = useState(() => client)

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <CartContextProvider storeConfig={storeConfig} cartId={cartId}>
          <StoreContextProvider storeCode={storeCode} storeConfig={storeConfig}>
            {children}
          </StoreContextProvider>
        </CartContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  )
}
