import { getABTestingCookie } from '@/modules/product/components/recommended-products/recommended-products.utils'
import { gtmTracker } from '../services/tracking/google/gtm-tracker'
import { CookieKeys, getCookie } from './cookie-utils'

export const isOfType = <T>(
  varToBeChecked: any | undefined | null,
  propertyToCheckFor: keyof T,
): varToBeChecked is T => {
  if (varToBeChecked) {
    return varToBeChecked.hasOwnProperty(propertyToCheckFor)
  }

  return false
}

export const trackABTesting = () => {
  const testCookies = getABTestingCookie(
    getCookie(CookieKeys.TEST_NAME),
    getCookie(CookieKeys.TEST_VARIANT),
  )

  gtmTracker.trackAbTestSetup(
    testCookies.map(({ name, variant }) => ({
      testName: name,
      testVariant: variant,
    })) ?? [],
  )
}
export const delay = (time: number) =>
  new Promise<boolean>((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
