'use client'

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { CartItemFragment, CartQuery, CurrencyEnum, useCartQuery } from '@/api'
import { StoreConfigType } from '@/common/types'
import { AddToCartData, useAddToCart } from './hooks'
import {
  gtmTracker,
  RecommendedProductTrackingOptions,
} from '@/common/services/tracking/google/gtm-tracker'
import { useAuthContext } from '../auth'
import { queryClient } from '@/common/services'
import { AddToCartParams } from './utils/add-to-cart.utils'

export type AddToCartTrackingConfig = {
  addingRecommendedProduct?: boolean
  skipTracking?: boolean
}

type CartContext = {
  hasItems: boolean
  cartItems: CartItemFragment[]
  isCartFetchError: boolean
  isCartRefetching: boolean
  isAddingToCart: boolean
  addToCart?: (
    data: AddToCartParams,
    trackingConfig?: AddToCartTrackingConfig,
  ) => Promise<void>
}

export const CartContext = createContext<CartContext>({} as CartContext)

type CartContextProviderProps = {
  storeConfig: StoreConfigType
  cartId?: string
}

const fetchCartData = async (
  cartId: string,
  setFn: (data: CartQuery) => void,
) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: useCartQuery.getKey({ cartId: `${cartId}-${Date.now()}` }),
      queryFn: useCartQuery.fetcher({ cartId }),
      retry: 5,
      retryDelay: 1000,
    })

    if (data.errors && !data.cart) {
      console.error({ error: data.errors, data: null })
      return
    }

    if (data.cart) {
      setFn(data)
    }
  } catch (err) {
    console.error(err)
  }
}

/**
 * Provider that handles the cart
 * @param storeConfig
 * @param children
 * @constructor
 */
export const CartContextProvider = ({
  storeConfig,
  children,
  cartId,
}: PropsWithChildren<CartContextProviderProps>) => {
  const { customerToken, guestToken } = useAuthContext()
  const [isCartRefetching, setIsCartRefetching] = useState(false)
  const [cartData, setCartData] = useState<CartQuery>()
  // cartId is sent from server with undefined value only when website is visiting by guest
  const [currCartId, setCurrCartId] = useState(cartId || guestToken || '')
  const { isAddingToCart, addToCart, isCartFetchError } = useAddToCart()

  useEffect(() => {
    if (customerToken) {
      setCurrCartId(cartId ?? '')
    } else if (guestToken) {
      setCurrCartId(guestToken)
    }
  }, [customerToken, cartId, guestToken])

  // TODO: implement notification for user to know that there was a problem to load cart data from the backend
  useEffect(() => {
    if (currCartId) {
      fetchCartData(currCartId, (cart) => {
        setCartData(cart)
      })
    }
  }, [currCartId])

  const cartItems = useMemo(
    () =>
      ((cartData?.cart?.items || []).filter(Boolean) ??
        []) as CartItemFragment[],
    [cartData?.cart?.items],
  )

  const handleAddToCartTracking = useCallback(
    (
      {
        brand,
        quantity,
        productName,
        sku,
        parentSku,
        price,
        category,
        prodlistPlacement,
        productIds,
        productPosition,
      }: AddToCartParams,
      addingRecommendedProduct: boolean,
    ) => {
      if (addingRecommendedProduct) {
        gtmTracker.trackRecommendedProductAddToCart()
      } else {
        gtmTracker.trackAddProductToCart(
          {
            id: parentSku ? Number(parentSku) : Number(sku),
            brand: brand ?? '',
            name: productName,
            category: category ?? '',
          },
          quantity,
          price ?? 0,
          (storeConfig.currency as CurrencyEnum) ?? CurrencyEnum.Eur,
          {
            prodlistPlacement,
            productIds,
            productPosition,
          },
        )
      }
    },
    [storeConfig.currency],
  )

  const addToCartWithCartId = useCallback(
    async (
      data: Omit<AddToCartData, 'cartId' | 'refreshTokens'> &
        RecommendedProductTrackingOptions,
      trackingConfig?: AddToCartTrackingConfig,
    ) => {
      setIsCartRefetching(true)
      let latestCartId = currCartId

      await addToCart({
        cartId: currCartId,
        refreshTokens: (token) => {
          latestCartId = token
          setCurrCartId(token)
        },
        onSuccess: () => {
          if (trackingConfig?.skipTracking) {
            return
          }

          handleAddToCartTracking(
            data,
            Boolean(trackingConfig?.addingRecommendedProduct),
          )
        },
        ...data,
      })

      fetchCartData(latestCartId, (cart) => {
        setCartData(cart)
        setIsCartRefetching(false)
      })
    },
    [currCartId, addToCart, handleAddToCartTracking],
  )

  const contextValue = useMemo(
    () => ({
      cartItems,
      isAddingToCart,
      isCartRefetching,
      isCartFetchError,
      hasItems: cartItems.length > 0,
      addToCart: addToCartWithCartId,
    }),
    [
      cartItems,
      isAddingToCart,
      isCartFetchError,
      isCartRefetching,
      addToCartWithCartId,
    ],
  )

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}

export const useCartContext = () => useContext(CartContext)
