import Cookies from 'js-cookie'

export enum CookieKeys {
  GAUID = '_ga',
  PHPSESSID = 'PHPSESSID',
  TEST_VARIANT = 'test_variant',
  TEST_NAME = 'test_name',
  CUSTOMER_TOKEN = 'customerToken',
  STORE_CODE = 'storeCode',
}

export function getCookie(key: string) {
  return Cookies.get(key)
}

export function setCookie(
  key: string,
  value: string,
  options?: Cookies.CookieAttributes,
): string | undefined {
  return Cookies.set(key, value, options)
}

export function removeCookie(key: string) {
  Cookies.remove(key)
}
