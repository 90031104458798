import {
  ConfigurableProductFragment,
  GiftCardProductFragment,
} from '../api/graphql'
import { processRequestUrl } from '@/utils'
import { isNullOrUndefined } from './is-null-or-undefined'
import { StoreConfigType } from '../types'
import {
  productQuestionPaginationFragment,
  productReviewPaginationFragment,
  SearchParams,
} from '../constants/url-constants'
import { SortingValues } from '@/modules/category/parts/filters/filters.const'

export interface HeadProps {
  productData?: ConfigurableProductFragment | GiftCardProductFragment
  storeConfig: StoreConfigType
}

type PrepareCanonicalUrlProps = {
  isData: boolean
  baseUrl: string
  requestUrl: string
}

export const prepareCanonicalUrl = ({
  baseUrl,
  isData,
  requestUrl,
}: PrepareCanonicalUrlProps) => {
  const reqUrl = new URL(requestUrl)
  const pathname = reqUrl.pathname

  if (!isData) {
    const url = new URL(baseUrl)
    url.pathname = pathname

    return url.toString()
  }

  const {
    questionsCurrentPage,
    questionPaginationUsed,
    reviewCurrentPage,
    reviewPaginationUsed,
    isReviewsCountSorting,
  } = processRequestUrl(requestUrl)

  const url = new URL(baseUrl)
  url.search = ''
  url.pathname = pathname

  if (questionPaginationUsed) {
    url.searchParams.append(
      productQuestionPaginationFragment,
      String(questionsCurrentPage),
    )
  }

  if (reviewPaginationUsed) {
    url.searchParams.append(
      productReviewPaginationFragment,
      String(reviewCurrentPage),
    )
  }

  if (isReviewsCountSorting) {
    url.searchParams.append(
      SearchParams.CategorySorting,
      SortingValues.ReviewsCount,
    )
  }

  return url.toString()
}

export const getFullCanonicalUrl = (
  baseUrl: string,
  canonicalUrl?: string | null,
) => {
  if (isNullOrUndefined(canonicalUrl)) {
    return baseUrl
  } else {
    return baseUrl.endsWith('/')
      ? `${baseUrl}${canonicalUrl ?? ''}`
      : `${baseUrl}/${canonicalUrl ?? ''}`
  }
}
