import { CurrencyEnum, ProductStockStatus } from '@/api'
import { CookieKeys } from '@/common/utils/cookie-utils'
import { setCookie } from '@/common/utils/cookie-utils'
import {
  AWSRecommendedProduct,
  ConfigurableVariantItem,
  RecommendedProductResponse,
} from './recommended-products.types'
import { Currency } from '@/common/types/price-types'
import { PriceRange } from '@/components/product-list-item/product-data-item-types'
import { arePricesDifferentInVariants } from '@/common/utils'

// can be used only in the browser
export function setABTestingCookie(
  testing: { name: string; variant: string }[],
) {
  if (testing.length >= 1) {
    setCookie(
      CookieKeys.TEST_NAME,
      testing.map((test) => test.name).join(';'),
      {
        expires: 30,
      },
    )
    setCookie(
      CookieKeys.TEST_VARIANT,
      testing.map((test) => test.variant).join(';'),
      {
        expires: 30,
      },
    )
  }
}

export function getABTestingCookie(
  testNames?: string,
  testVariants?: string,
): { name: string; variant: string }[] {
  if (testNames && testVariants) {
    return testNames.split(';').map((name, index) => ({
      name,
      variant: testVariants.split(';')[index],
    }))
  }

  return []
}

export function convertToJsonString(inputString: string) {
  // Add double quotes to keys
  let validJsonString = decodeURIComponent(inputString).replace(
    /([{,]\s*)([a-zA-Z0-9_]+)(\s*:)/g,
    '$1"$2"$3',
  )

  // Add double quotes to string values (but not to booleans, numbers, or nulls)
  validJsonString = validJsonString.replace(/:\s*'([^']+)'/g, ': "$1"')

  return validJsonString
}

export function getCookieMktConsent(mktConsent?: string) {
  if (mktConsent) {
    const cookieConsent = convertToJsonString(mktConsent)

    return JSON.parse(cookieConsent).marketing
  }

  return false
}

export function formatGAUID(gaUID: string) {
  if (gaUID) {
    return gaUID.split('.').slice(-2).join('.')
  }

  return ''
}

export function formatRecommendedProductForProductListItem(
  product: RecommendedProductResponse,
  storeConfigCurrency?: CurrencyEnum,
) {
  const priceRange = product.configurable_variants.reduce(
    (acc: PriceRange, curr: ConfigurableVariantItem) => ({
      isPriceRange:
        product.configurable_variants.length >= 2 &&
        arePricesDifferentInVariants(
          product.configurable_variants.map(
            (variant) => variant.product.price.final_price,
          ),
        ),
      price:
        Number(acc.price) > curr.product.price.final_price
          ? curr.product.price.final_price
          : acc.price,
      currency: storeConfigCurrency || Currency.Eur,
      formattedPrice:
        Number(acc.price) > curr.product.price.final_price
          ? curr.product.price.formatted_price
          : acc.formattedPrice,
    }),
    {
      isPriceRange: false,
      currency: Currency.Eur,
      formattedPrice: '',
      price: Number.MAX_SAFE_INTEGER,
    } as PriceRange,
  )

  const isProductSingleVariant = product.configurable_variants.length === 1
  const singleVariantSku = isProductSingleVariant
    ? product.configurable_variants[0].product.sku
    : product.sku
  const ratingSummary = Number(product?.['rating-result'] ?? '0')
  const stockStatus = product?.is_saleable
    ? ProductStockStatus.InStock
    : ProductStockStatus.OutOfStock
  const thumbnail = {
    url: product?.image.url,
    label: product?.image.alt,
  }
  const enabled = product?.long_term_unavailable === '0'
  const reviewCount = Number(product?.reviews_count) ?? 0
  const sku = isProductSingleVariant ? singleVariantSku : product.sku
  const canonicalUrl = new URL(product.canonical_url).pathname
  const skus = product.configurable_variants.map(
    (variant) => variant.product.sku,
  )
  const id = Number(product.id)
  const image = {
    url: {
      small: product.image.url,
    },
    label: product.image.alt,
  }
  const longTermUnavailable = Number(product.long_term_unavailable) ?? null
  const breadcrumbEn = product.breadcrumb_en
  const productLabels = product.product_labels.map((label) => ({
    ...label,
    label: Number(label.label),
  }))

  return {
    priceRange,
    isProductSingleVariant,
    ratingSummary,
    stockStatus,
    thumbnail,
    enabled,
    reviewCount,
    sku,
    singleVariantSku,
    canonicalUrl,
    skus,
    id,
    image,
    longTermUnavailable,
    breadcrumbEn,
    productLabels,
  }
}

export const getPositionOfProduct = (
  recommendedProducts: AWSRecommendedProduct[],
  variants: ConfigurableVariantItem[],
) => {
  const prodSimpleIds = variants.map((variant) => variant.product.id)
  const recommendedSimpleIds = recommendedProducts.map(
    (prod) => prod.product_id_simple,
  )
  return (
    recommendedSimpleIds.findIndex((simpleId) =>
      prodSimpleIds.includes(simpleId.toString()),
    ) + 1
  )
}

export const getRecommendedProducts = (
  limit: number,
  products: RecommendedProductResponse[],
  recommendedProducts: AWSRecommendedProduct[],
): RecommendedProductResponse[] => {
  return products
    .filter((prod) => prod.is_saleable && prod.long_term_unavailable === '0')
    .slice(0, limit)
    .sort((a, b) =>
      getPositionOfProduct(recommendedProducts, a.configurable_variants) <=
      getPositionOfProduct(recommendedProducts, b.configurable_variants)
        ? -1
        : 1,
    )
}
