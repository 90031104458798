import { cache } from 'react'
import { QueryClient } from '@tanstack/react-query'

const staleTime = 20 * 1000

// client-side query client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime,
    },
  },
})

// server-side query client
export const getQueryClientServer = cache(
  () =>
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime,
        },
      },
    }),
)
