/**
 * Function processes the query string by:
 * * normalizing the query,
 * * applying the variables into the query.
 *
 * For more info see also:
 * * {@link normalizeQuery}
 * * {@link processVariablesIntoQuery}
 *
 * @param query - query string for processing
 * @param variables - variables object that needs to be applied - (type of this prop is checked whether it's an object)
 */
export const processQuery = <TVariables>(
  query: string,
  variables?: TVariables,
): string => {
  const normalizedQuery = normalizeQuery(query)
  return processVariablesIntoQuery(normalizedQuery, variables)
}

/**
 * Function normalizes the query string by:
 * * removing the custom name,
 * * removing unnecessary new lines,
 * * replacing multiple consecutive spaces with only one.
 *
 * @param query - query string that needs to be normalized
 */
const normalizeQuery = (query: string): string => {
  return query
    .replace(/(^\s*query .*?)\{([\s\S]*)\}/g, '{$2}')
    .replace(/[\n\r]+/g, '\n')
    .replace(/\s+/g, ' ')
    .replace(/^([{}])[\r\t\s]+/g, '$1')
}

/**
 * Function applies the arguments from the variables into the query, missing arguments are removed from the query
 * (assuming that the variables is an object of type {[string]: primitive},
 * where primitive value could be string, number boolean,...)
 * if the variables isn't an object it is ignored
 *
 * TODO: known bug: if one variable is a substring of another variable e.g. review and reviewNumber it is possible
 * TODO: that replace function breaks the query, better Regex would solve this issue
 *
 * @param query - query string for applying
 * @param variables - (probably) object of type {[string]: primitive}
 */

const processVariablesIntoQuery = <TVariables>(
  query: string,
  variables?: TVariables,
): string => {
  const queryWithVariables =
    typeof variables === 'object' && variables !== null
      ? Object.keys(variables ?? {}).reduce((processingQuery, variableKey) => {
          const variableValue = processVariable(
            variables[variableKey as keyof typeof variables],
          )
          let newQuery = processingQuery
          while (newQuery.includes(`$${variableKey}`)) {
            newQuery = newQuery.replace(`$${variableKey}`, variableValue)
          }
          return newQuery
        }, query)
      : query

  return (
    queryWithVariables
      // remove unused arguments from query
      .replace(/[a-z]+: \$[a-z]+/gi, '')
      // remove empty brackets (all arguments were removed)
      .replace(/\(\s*\)/g, '')
  )
}

/**
 * Function stringifies the variable (if the variable is string, it wraps it into the " ")
 * @param variable
 */
const processVariable = (variable: unknown): string => {
  if (typeof variable === 'string') {
    return isEnum(variable) ? variable : `"${variable}"`
  } else if (Array.isArray(variable)) {
    return `[${variable.map((item) => processVariable(item)).join(', ')}]`
  } else if (typeof variable === 'object' && variable !== null) {
    const object = Object.entries(variable)
      .map(([key, value]) => `${key}: ${processVariable(value)}`)
      .join(', ')

    return `{${object}}`
  }

  return String(variable)
}

/**
 * HACK: This will only work if graphql enums are declared in uppercase, while
 * strings have any format except for uppercase! We could also use a list of
 * available enums to check them.
 * TODO: Remove the whole file.
 */
const isEnum = (val: string) => {
  return /^[A-Z_]+$/.test(val)
}
